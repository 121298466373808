<template>
  <div
      class="f6 h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="profile-container">
          <MetaRightSideHeader>
            <template #title>
              账号信息
            </template>
            <template #bottom-tip>
              查看或编辑个人信息
            </template>
          </MetaRightSideHeader>
          <div class="shadow content">
            <div class="header">
              <div class="user-avatar-wrap" @click="avatarUploaderShow = true">
                <div class="user-avatar-wrap--reset">
                  <span>更改头像</span>
                </div>
                <user-icon :url="userInfo.headImgUrl" :size="60" :name="userInfo.userName" :uid="userInfo.userName"></user-icon>
<!--                <el-avatar :src="userInfo.headImgUrl" :size="60"></el-avatar>-->

              </div>
            </div>

            <div class="userInfo f5">
              <div class="userInfo-item">
                 <p class="userInfo-item-p">用户名</p>
                <p class="userInfo-item-p userInfo-item-content">
                <span class="userInfo-text truncate">{{userInfo.userName}}</span>
                <span class="userInfo-item-p-edit" data-tag-id="my-details-modify-user-name" @click="updateDialogVisible('name')">修改</span>
              </p>
              </div>
              <div class="userInfo-item">
                <p class="userInfo-item-p">邮箱</p><p class="userInfo-item-p userInfo-item-content">
                <span class="userInfo-text truncate">{{userInfo.email}}</span>

                <span class="userInfo-item-p-edit" data-tag-id="my-details-modify-email" @click="updateDialogVisible('email')">修改</span>
              </p>
              </div>
              <div class="userInfo-item">
                <p class="userInfo-item-p">手机</p><p class="userInfo-item-p userInfo-item-content">
                <span class="userInfo-text truncate">{{userInfo.phone}}</span>
                <span class="userInfo-item-p-edit" data-tag-id="my-details-modify-phone-code" @click="updateDialogVisible('phone')">修改</span>

              </p>
              </div>
              <div class="userInfo-item">
                <p class="userInfo-item-p">团队</p><p class="userInfo-item-p userInfo-item-content">
                  <span class="userInfo-text truncate">{{asyncData.mainTeamInfo.teamName}}</span>
                  <span class="userInfo-item-p-edit" data-tag-id="my-details-modify-team-info" @click="$router.push({name:'teamManagement'})" v-if="asyncData.mainTeamInfo.teamName">修改</span>
                </p>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <el-dialog
        v-model="userNameDialogVisible"
        title="修改用户名"
        width="460px"
    >
      <el-form ref="userInfoRef" :rules="inputRules"  :model="userInfo" label-width="0" class="text-inputs-container">
        <el-form-item prop="userName">
          <el-input class="text-input" placeholder="请输入您的姓名" v-model="newUserName"></el-input>
        </el-form-item>
        <div class="submit-btn">
          <el-button data-tag-id="user-phone-login" @click="saveUserInfo()" type="primary"
                     class="text-input"
          >
            确认
          </el-button>

        </div>
      </el-form>
    </el-dialog>
    <el-dialog
        v-model="phoneEditDialogVisible"
        title="修改手机号"
        width="630px"
    >
      <el-form :model="userInfo"  :rules="inputRules" class="phone-type-form"  label-position="left" label-width="100px"  ref="phoneForm">
        <el-form-item  prop="newphone"  label="新的手机号">
          <el-input placeholder="输入手机号码" v-model="userInfo.newphone" class="register-phone-wrap">
            <template #prepend>
              <el-input v-model="userInfo.countryCode"  placeholder="86" class="register-codeinput"></el-input>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item   prop="verification" label="验证码" >
          <el-col :span="16">
            <el-input v-model="userInfo.verification" placeholder="请输入4位验证码"></el-input>
          </el-col>
          <el-col :span="8" style="text-align: right;">
            <el-button :disabled="newphoneCodeButton.isDisabled" @click="getPhoneCodeFunc(userInfo.newphone,true)">{{ newphoneCodeButton.name }}</el-button>
          </el-col>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="phoneEditDialogVisible=false" type="default" size="medium">取 消</el-button>
          <el-button type="primary" @click="phoneCodeCheckFunc('')" size="medium">确 定</el-button>
        </div>
      </template>


    </el-dialog>
    <el-dialog
        v-model="phoneCodeValidateDialogVisible"
        title="手机验证"
        width="630px"
    >
      <el-form :model="userInfo"  :rules="inputRules" class="phone-type-form" label-position="left" label-width="80px"  ref="checkPhoneForm">
        <p style="margin-bottom: 20px; color: #111111; font-size: 14px;">为确保账户安全，请点击“发送验证码”接收短信，并完成验证</p>
        <el-form-item    label="手机号">
          <el-input  v-model="userInfo.phone"  disabled></el-input>
        </el-form-item>
        <el-form-item   prop="verification" label="验证码" >
          <el-col :span="16">
            <el-input v-model="userInfo.verification" placeholder="请输入4位验证码"></el-input>
          </el-col>
          <el-col :span="8" style="text-align: right;">
            <el-button :disabled="phoneCodeButton.isDisabled" @click="getPhoneCodeFunc(userInfo.phone)">{{ phoneCodeButton.name }}</el-button>
          </el-col>
        </el-form-item>
      </el-form>
      <template #footer>
        <div  class="dialog-footer">
          <el-button @click="phoneCodeValidateDialogVisible=false" type="default" size="medium">取 消</el-button>
          <el-button type="primary" @click="phoneCodeCheckFunc(type)" size="medium">确 定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
        v-model="emailDialogVisible"
        title="修改邮箱"
        width="630px"
    >
      <el-form :model="userInfo"  :rules="inputRules"  label-position="left" label-width="70px"  ref="emailForm" >
        <el-form-item  prop="newemail"  label="邮箱">
          <el-input  v-model="userInfo.newemail" placeholder="请输入邮箱" ></el-input>
        </el-form-item>
        <el-form-item   prop="emailverification" label="验证码" >
          <el-col :span="16">
            <el-input v-model="userInfo.emailverification" placeholder="请输入4位验证码"></el-input>
          </el-col>
          <el-col :span="8" style="text-align: right;">
            <el-button :disabled="emailCodeButton.isDisabled" @click="getEmailCodeFunc">{{ emailCodeButton.name }}</el-button>
          </el-col>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="emailDialogVisible=false" type="text" size="medium">取 消</el-button>
          <el-button type="primary" @click="emailCodeCheckFunc" size="medium">确 定</el-button>
        </div>
      </template>

    </el-dialog>

    <div class="upload-avatar" v-if="avatarUploaderShow">
        <myUpload field="img"
                  crop="local"
                  v-model="avatarUploaderShow"
                  @crop-success="cropSuccess"
                  :width="120"
                  :height="120"
                  :size="size"
                  :langExt="langExtUpload"/>
    </div>
  </div>
</template>

<script>
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import {
  checkPhoneCode,
  getMyInfo,
  getPhoneCode,
  requestLogin,
  updateUserInfo,
  getEmailCode,
  checkEmailCode,
  updateUserNickName,
  uploadImg,
  getTeamInfo
} from "../api/api";
import config from "../config";
import {colorLog, deepClone, MetaMessage} from "../util";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import UserIcon from "../components/UserIcon";
import {ElMessage} from "element-plus";
import {globalTagClick} from "../assets/js/tag/tag";
import {checkUserBehaviorPermission,BehaviorPermission} from "../components/common/permissionUtil";
import myUpload from 'vue-image-crop-upload'

export default {
  name: "MyDetails",
  components: {UserIcon, MetaRightSideHeader, ContractSettingLeftMenu,myUpload},
  data(){
    var checkPhoneNum = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else if (isNaN(value)) {
        callback(new Error('请输入0-9的数字'))
      } else if (value.length < 8 || value.length > 14) {
        callback(new Error('请输入8-14位有效手机号码'))
      } else {
        callback()
      }
    }
    var checkVerification = (rule, value, callback) => {
      if (!value) {
        callback(new Error('验证码不能为空'))
      } else if (isNaN(value)) {
        callback(new Error('请输入数字值'))
      } else {
        callback()
      }
    }
    var checkTeamTel = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error('请输入数字值'))
      } else {
        callback()
      }
    }
    var checkEmail = (rule, value, callback) => {
      let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
      if (!value) {
        callback(new Error('邮箱不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('邮箱格式不正确'))
      } else {
        callback()
      }
    }
    var checkPasswordValid = (rule, value, callback) => {
      let reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,40}$/
      if (!value) {
        callback(new Error('密码不能为空'))
      } else if (value.length < 8) {
        callback(new Error('密码最少不能低于8位'))
      } else if (value.length > 40) {
        callback(new Error('密码最多不能超过40位'))
      } else if (!reg.test(value)) {
        callback(new Error('必须包含大写字母、小写字母、数字、符号中三种以上字符'))
      } else {
        callback()
      }
    }
    return{
      //表单验证配置
      inputRules: {
        userName: [
          {
            required: true, validator: (rule, value, callback) => {
              if (!value || /\s/.test(value)) {
                callback(new Error('姓名不能为空或包含空格'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        email: [
          { type: 'email', message: '请输入有效的邮箱', trigger: 'blur'},
          { validator: checkEmail, trigger: 'blur' }
        ],
        newphone: [
          { validator: checkPhoneNum, trigger: 'blur'}
        ],
        verification: [
          { min: 4, max: 4, message: '请输入4位手机验证码', trigger: 'blur'},
          { validator: checkVerification, trigger: 'blur' }
        ],
        emailverification: [
          { min: 4, max: 4, message: '请输入4位邮箱验证码', trigger: 'blur'},
          { validator: checkVerification, trigger: 'blur' }
        ],
      },

      phoneCodeButton: {
        isDisabled: false,
        name: '获取短信验证码',
        time: 60
      },
      newphoneCodeButton: {
        isDisabled: false,
        name: '获取短信验证码',
        time: 60
      },
      emailCodeButton: {
        isDisabled: false,
        name: '获取邮箱验证码',
        time: 60
      },
      BehaviorPermission,
      newphonetimer:null,
      phonetimer:null,

      userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
      asyncData:{mainTeamInfo:''},
      phoneValidateCode:sessionStorage.getItem("phoneValidateCode"),

      userNameDialogVisible:false,
      phoneEditDialogVisible:false,
      emailDialogVisible:false,
      phoneCodeValidateDialogVisible:false,
      newUserName:'',
      type:'',//当前打开的窗口是修改什么的
      avatarUploaderShow:false,
      size: 1,
      langExtUpload: {
        preview: '图片预览'
      }
    }
  },
  watch:{
    phoneCodeValidateDialogVisible: function (val, oldVal) {
      if (!val) {
        this.userInfo.verification = ''
      }
    },
    phoneEditDialogVisible: function (val, oldVal) {
      this.userInfo.verification = ''
    },
  },
  created() {
    this.getAsyncData();
  },
  mounted() {

  },
  methods:{
    globalTagClick,
    checkUserBehaviorPermission,
    async getAsyncData(){
      let teamInfo = (await getTeamInfo(this.userInfo.mainSiteTeamId)).data.data;
      this.asyncData.mainTeamInfo = teamInfo;
    },
    saveUserInfo(){
      this.$refs.userInfoRef.validate().then(async ()=>{
        let res = await updateUserNickName(this.newUserName);

        if(res.data.code == 0 || res.data.code == 200){
          ElMessage.success("设置成功");

          await this.resetUserInfo();
          this.userNameDialogVisible = false;
        }else{
          ElMessage.error("设置失败");
        }
        //输入的姓名字符串合法
      }).catch((e)=>{
        ElMessage.error("设置失败");
      })
    },
    async resetUserInfo(){
      let res = await getMyInfo();
      if(res.data.code != 0) return;
      //重置当前保存的用户信息
      sessionStorage.setItem('userInfo', JSON.stringify(res.data.userInfo));
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    },
    updateDialogVisible: function (type) {
      this.type = type;

      switch (type) {
        case 'name':
          this.newUserName = this.userInfo.userName;
          this.userNameDialogVisible = true;
          break
        case 'email':
          this.phoneCodeValidateDialogVisible = true;
          break
        case 'pass':
          this.phoneCodeValidateDialogVisible = true;
          break
        case 'phone':
          this.phoneCodeValidateDialogVisible = true;
          break
      }
    },
    getPhoneCodeFunc: function (phone, isnew) {
      let _this = this;
      if (!phone) {
        _this.$message({
          type: 'error',
          message: '请填写手机号码'
        })
      } else {
        let type = {type: 'sms'};
        if (isnew) {
          type = {type: 'unlock'}
        }
        let params = {
          phone: phone
        };
        params.phone = _this.userInfo.countryCode === '' || _this.userInfo.countryCode === undefined ?
            '86-' + _this.trimOrSetEmpty(phone)
            : _this.trimOrSetEmpty(phone) + '-' + _this.trimOrSetEmpty(phone)
        getPhoneCode(type, params).then(res => {
          if (isnew) {
            _this.checkNewPhoneCallBackFunc(res)
          } else {
            _this.checkPhoneCallBackFunc(res)
          }
        }).catch(res => {
          _this.$message({
            type: 'error',
            message: '发送手机验证码失败，请稍后再试'
          })
        })
      }
      _this.globalTagClick('sendPhoneVerifyCode', {phone: phone})
    },
    // 获取验证码成功后的回调操作
    checkNewPhoneCallBackFunc: function (res) {
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '验证码已发送，请查看手机短信'
        })
        let _this = this
        _this.newphoneCodeButton.isDisabled = true
        _this.newphonetimer = window.setInterval(function () {
          _this.newphoneCodeButton.name = '(' + _this.phoneCodeButton.time + '秒)后重新发送'
          --_this.newphoneCodeButton.time
          if (_this.newphoneCodeButton.time < 0) {
            _this.newphoneCodeButton = {
              isDisabled: false,
              name: '获取短信验证码',
              time: 60
            }
            window.clearInterval(_this.newphonetimer)
          }
        }, 1000)
      } else {
        this.$message({
          type: 'error',
          message: res.data.msg
        })
      }
    },
    // 获取验证码成功后的回调操作
    checkPhoneCallBackFunc: function (res) {
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '验证码已发送，请查看手机短信'
        })
        let _this = this
        _this.phoneCodeButton.isDisabled = true
        _this.phonetimer = window.setInterval(function () {
          _this.phoneCodeButton.name = '(' + _this.phoneCodeButton.time + '秒)后重新发送'
          --_this.phoneCodeButton.time
          if (_this.phoneCodeButton.time < 0) {
            _this.phoneCodeButton = {
              isDisabled: false,
              name: '获取短信验证码',
              time: 60
            }
            window.clearInterval(_this.phonetimer)
          }
        }, 1000)
      } else {
        this.$message({
          type: 'error',
          message: res.data.msg
        })
      }
    },
    emailCodeCheckFunc: function () {
      let params = {
        type: 'new_email',
        content: this.userInfo.emailverification
      }
      this.$refs['emailForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.checkEmailCode(params)
      })
    },
    checkEmailCode: function (params) {
      let _this = this
      checkEmailCode(params).then(res => {
        if (res.data.code === 0) {
          _this.emailDialogVisible = false
          _this.updateUserInfoFunc(function () {
            _this.userInfo.email = _this.userInfo.newemail;
            _this.userInfo.newemail = null;
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      }).catch(res => {
        this.$message({
          type: 'error',
          message: '验证码验证失败，请重新输入'
        })
      })
    },
    phoneCodeCheckFunc: function (type) {
      colorLog.red(`phoneCodeCheckFunc 检查验证码 type: ${type}`);
      let _this = this;
      // type 没有传值表示修改手机号
      let params;
      let formName;
      if (type) {//验证手机
        colorLog.red(`验证手机`);
        // 修改密码应该先填邮箱
        if (type === 'pass' && !_this.userInfo.email) {
          this.$message({
            type: 'info',
            message: '请先绑定邮箱，再修改密码'
          });
          return
        }
        params = {
          type: 'sms',
          content: _this.userInfo.verification
        }
        formName = 'checkPhoneForm'
      } else {//修改手机号
        colorLog.red(`修改手机号`);
        if (_this.userInfo.phone === _this.userInfo.newphone) {
          _this.$message({
            type: 'info',
            message: '您输入的手机号没有发生变化'
          })
          return
        }
        params = {
          type: 'unlock',
          content: _this.userInfo.verification
        }
        formName = 'phoneForm'
      }
      _this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }
        _this.checkPhoneCode(type, params)
      })
    },
    checkPhoneCode: function (type, params) {
      let _this = this;
      checkPhoneCode(params).then(res => {
        if (res.data.code === 0) {
          if (type === 'phone') {
            _this.phoneCodeValidateDialogVisible = false;
            _this.phoneEditDialogVisible = true
          } else if (type === 'pass') {
            _this.phoneCodeValidateDialogVisible = false;
            _this.passDialogVisible = true
          } else if (type === 'email') {
            _this.phoneCodeValidateDialogVisible = false;
            _this.emailDialogVisible = true
          } else {
            if (type === 'pass') {
              _this.passDialogVisible = false;
              _this.showSuccess()
              _this.resetUserInfo()
            } else {
              _this.phoneEditDialogVisible = false;
              _this.updateUserInfoFunc(function () {
                _this.userInfo.newphone = null;
              })
            }
          }
        } else {
          _this.$message({
            type: 'error',
            message: '验证码验证失败，请重新输入'
          })
        }
      }).catch(res => {
        _this.$message({
          type: 'error',
          message: '验证码验证失败，请重新输入'
        })
      })
    },
    getEmailCodeFunc: function () {
      if (!this.userInfo.newemail) {
        this.$message({
          type: 'error',
          message: '请填写邮箱号码'
        })
      } else {
        getEmailCode({type: 'new_email'}, {'email': this.userInfo.newemail}).then(res => {
          this.checkEmailCallBackFunc(res)
        }).catch(res => {
          this.$message({
            type: 'error',
            message: '发送邮箱验证码失败，请稍后再试'
          })
        })
      }
      this.globalTagClick('sendEmailVerifyCode', {email: this.userInfo.newemail})
    },
    // 获取验证码成功后的回调操作
    checkEmailCallBackFunc: function (res) {
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '验证码已发送，请查看邮箱'
        })
        let _this = this
        _this.emailCodeButton.isDisabled = true
        _this.emailtimer = window.setInterval(function () {
          _this.emailCodeButton.name = '(' + _this.emailCodeButton.time + '秒)后重新发送'
          --_this.emailCodeButton.time
          if (_this.emailCodeButton.time < 0) {
            this.emailCodeButton = {
              isDisabled: false,
              name: '获取邮箱验证码',
              time: 60
            }
            window.clearInterval(_this.emailtimer)
          }
        }, 1000)
      } else {
        this.$message({
          type: 'error',
          message: res.data.msg
        })
      }
    },
    showSuccess: function () {
      let message = '修改个人信息成功'
      switch (this.type) {
        case 'name':
          message = '修改昵称成功'
          break
        case 'email':
          message = '修改邮箱成功'
          break
        case 'pass':
          message = '修改密码成功'
          break
        case 'phone':
          message = '修改手机号成功'
          break
      }
      this.$message({
        type: 'success',
        message: message
      })
    },
    updateUserInfoFunc: function (callback) {
      let _this = this;
      colorLog.red(`updateUserInfoFunc方法`);
      let params = {
        name: _this.trimOrSetEmpty(this.userInfo.userName),
        nickname: _this.trimOrSetEmpty(this.userInfo.userName),
        email: _this.trimOrSetEmpty(this.userInfo.newemail),
        company: _this.trimOrSetEmpty(this.userInfo.company),
        addr: _this.trimOrSetEmpty(this.userInfo.addr),
        slogan: _this.trimOrSetEmpty(this.userInfo.slogan),
        interest: _this.userInfo.interest,
        uid: _this.userInfo.uid
      };

      if (_this.userInfo.newphone) {
        params = {
          phone: _this.userInfo.newphone,
          uid: _this.userInfo.uid
        };
      }
      if (this.userInfo.newemail) {
        params = {
          email: _this.userInfo.newemail,
          uid: _this.userInfo.uid
        };
      }
      colorLog.red(`updateUserInfo接口调用 params:`,params);
      updateUserInfo(params).then(res => {
        colorLog.red(`updateUserInfo接口调用 res:`,res);
        if (res.data.code === 0) {
          _this.showSuccess();
          if (callback) {
            callback()
          }
          _this.resetUserInfo();
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      }).catch(res => {
        this.$message({
          type: 'error',
          message: res.msg
        })
      })
    },
    trimOrSetEmpty (param) {
      if (param === null || param === undefined) {
        return param
      } else {
        return param.trim()
      }
    },
    cropSuccess(imgDataUrl) {
      //imgDataUrl其实就是经过base64转码过的图片
      let params = {
        src: '',
        data: imgDataUrl,
        acl: 'read'
      }
      uploadImg(params).then(res => {
        if (res.data.code === 0) {
          let pa = {
            headimgurl: res.data.key,
            uid: this.userInfo.uid
          }
          updateUserInfo(pa).then(res => {
            if (res.data.code === 0) {
              this.avatarUploaderShow = false
              this.resetUserInfo();
            }
          })
        }
      }).catch(res => {
        console.log(res)
      })
    }
  }
  ,


}
</script>

<style scoped>
.profile-container{
  flex: 1;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
}
.content{
  flex: 1;
  background: white;
  padding: 20px;
  text-align: left;
}

.title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title h1{
  margin: 0;
}

.content .header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.userInfo{
  max-width: 550px;
  color: #555;
}
.userInfo-item{
  display: flex;
  padding: 5px;
}
.userInfo-item:not(:last-child){
  border-bottom: 1px solid #cccc;
}
.userInfo-item-p{
  min-width: 80px;
  margin: 0;
}
.userInfo-item-p-edit{
  margin-left:15px;color: var(--lightBlue);cursor:pointer;
  flex: none;
  transition:.2s color;
}
.userInfo-item-p-edit:hover{
  color:var(--darkBlue);
}
.userInfo-item-content{
  flex: 1;
}
.userInfo-item-content{
  display: inline-flex;
  justify-content: space-between;
}
.register-phone-wrap >>> .el-input__inner{
  padding-left: 60px;
  border-radius: 4px;
}
.register-phone-wrap {
  position: relative;
}
.register-phone-wrap >>> .el-input-group__prepend {
  width: 50px;
  padding: 0;
  border: 0;
  position: absolute;
  background: transparent;
}
.phone-type-form >>> .el-form-item__content{
  display: flex;
}
.register-phone-wrap >>> .el-input-group__prepend .el-input__inner {
  text-align: center;
  border: 0;
  border-radius: 0;
  position: relative;
  height: 12px;
  line-height: 12px;
  vertical-align: middle;
  margin-left: 1px;
  padding: 10px 0;
  border-right: 1px solid #dcdfe6;
}
.user-avatar-wrap{
  position:relative;
  border-radius: 50%;
  cursor:pointer;
  overflow: hidden;
}
.user-avatar-wrap--reset {
  display: inline-block;
  text-align: center;
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  opacity: 0;
  z-index: 1;
  color: #ffff;
  transition: .3s;
  backdrop-filter: blur(3px);
  background: rgba(0,0,0,.2);
}

.user-avatar-wrap--reset::before{
  content:'';
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.user-avatar-wrap--reset > span{
  vertical-align: middle;
}
.user-avatar-wrap .user-icon-container{
  transition: .3s;
}
.user-avatar-wrap:hover .user-icon-container{
}
.user-avatar-wrap:hover >>> .el-avatar{
  transition: .3s;
  border-color:transparent !important;
}
.user-avatar-wrap:hover .user-avatar-wrap--reset{
  opacity: 1;
}
.upload-avatar {

}
.upload-avatar >>> .vicp-preview-item{
  box-sizing: content-box !important;
}
</style>
